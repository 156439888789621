.formBody {
  border: 0.5px solid #e5e5e5;
  border-radius: 4px;
  color: #048ba8;

  input,
  label,
  div {
    color: #048ba8;
  }

  input:focus {
    border-bottom: #ffffff solid 1px;
  }

  input:-internal-autofill-selected {
    background-color: transparent;
  }
}
.login {
  background-color: #048ba8;
  border-radius: 4px;
  button {
    color: #ffffff;
  }
}
.errorBox {
  border: 1px solid #e56b6f;
  background-color: #e56b6f;
  color: #ffffff;
  border-radius: 4px;
}

.title {
  color: #048ba8;
}
