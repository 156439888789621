.title {
  color: #048ba8;
}
.errorBox {
  border: 1px solid #e56b6f;
  background-color: #e56b6f;
  color: #ffffff;
  border-radius: 4px;
}

.successBox {
  border: 1px solid #80b918;
  background-color: #80b918;
  color: #ffffff;
  border-radius: 4px;
}
.send {
  background-color: #048ba8;
  border-radius: 4px;
  button {
    color: #ffffff;
  }
}

.logout {
  background-color: #e56b6f;
  border-radius: 4px;
  font-size: small;
  color: #ffffff;
  padding: 2px 10px;
  text-transform: none !important;
}
