.topBar {
  border: 1px solid #048ba8;
  background-color: #048ba8;
  color: #ffffff;
  border-radius: 4px;
  div {
    color: #ffffff;
  }

  svg {
    color: #ffffff;
  }
}
.appBar {
  height: 50px;
}
